<template>
  <div class="promotion-page" :class="{ mobile: mobileMode }">
    <div class="page-banner">
      <img class="banner-layer1" src="@/assets/wbsc/banner-layer1.png" />
      <img class="banner-layer2" src="@/assets/wbsc/banner-layer2.png" />
      <img class="banner-layer3" src="@/assets/wbsc/banner-layer3.png" />
      <img class="banner-layer4" src="@/assets/wbsc/banner-layer4.png" />
      <div class="banner-countdown1">Day Hour Min Sec</div>
      <div class="banner-countdown2">{{ makeCountdownText() }}</div>
      <img class="banner-watch" src="@/assets/wbsc/banner-watch.png" @click="showTgChannel()" />
      <img class="banner-wbsc" src="@/assets/wbsc/banner-wbsc.png" />
    </div>
    <div class="page-section ranking">
      <img class="section-title-bg" src="@/assets/wbsc/section-title-bg.png" />
      <img class="section-title" src="@/assets/wbsc/ranking-title.png" />
      <img class="ranking-top5" src="@/assets/wbsc/ranking-top5.png" />
      <img v-if="mobileMode" class="ranking-top12" src="@/assets/wbsc/ranking-top12-m.png" />
      <img v-else class="ranking-top12" src="@/assets/wbsc/ranking-top12.png" />
    </div>
    <div class="page-section group">
      <img class="section-title-bg" src="@/assets/wbsc/section-title-bg.png" />
      <img class="section-title" src="@/assets/wbsc/group-title.png" />
      <div class="section-panel">
        <div class="panel-tabs">
          <div
            class="panel-tab"
            :class="{ active: activeGroup === AGroupKey }"
            @click="setActiveGroup(AGroupKey)"
            >A組</div
          >
          <div
            class="panel-tab"
            :class="{ active: activeGroup === BGroupKey }"
            @click="setActiveGroup(BGroupKey)"
            >B組</div
          >
          <div
            class="panel-tab"
            :class="{ active: activeGroup === SuperRoundKey }"
            @click="setActiveGroup(SuperRoundKey)"
            >超級循環賽</div
          >
        </div>
        <div class="panel-header">
          <div class="item-column team">隊伍</div>
          <div class="item-column win">勝</div>
          <div class="item-column lose">敗</div>
          <div class="item-column win-rate">勝率</div>
          <div class="item-column win-diff">勝差</div>
          <div class="item-column win-score">得分</div>
          <div class="item-column lose-score">失分</div>
          <div class="item-column tqb">TQB</div>
          <div class="item-column odds">冠軍賠率</div>
        </div>
        <div class="panel-body">
          <div v-for="(item, i) in groups[activeGroup]" :key="i" class="panel-item">
            <div class="item-column team left">
              <img class="team-flag" :src="require(`@/assets/wbsc/team/${item[0]}.png`)" />
              {{ $t(`wbsc.${item[0]}`) }}
            </div>
            <div class="item-column win">{{ item[1] }}</div>
            <div class="item-column lose">{{ item[2] }}</div>
            <div class="item-column win-rate">{{ item[3] }}</div>
            <div class="item-column win-diff">{{ item[4] }}</div>
            <div class="item-column win-score">{{ item[5] }}</div>
            <div class="item-column lose-score">{{ item[6] }}</div>
            <div class="item-column tqb">{{ item[7] }}</div>
            <div class="item-column odds">{{ item[8] }}</div>
          </div>
        </div>
        <div class="panel-footer">
          <img class="bet-button" src="@/assets/wbsc/bet.png" @click="showTgChannel()" />
        </div>
      </div>
    </div>
    <div class="page-section schedule">
      <img class="section-title-bg" src="@/assets/wbsc/section-title-bg.png" />
      <img class="section-title" src="@/assets/wbsc/schedule-title.png" />
      <div class="section-panel">
        <div class="panel-header">
          <div class="item-column date">日期</div>
          <div class="item-column time">時間</div>
          <div class="item-column away">客隊</div>
          <div class="item-column score">比分</div>
          <div class="item-column home">主隊</div>
          <div class="item-column stage">階段</div>
          <div class="item-column place">場地</div>
        </div>
        <div class="panel-body">
          <div v-for="(item, i) in matchs" :key="i" class="panel-item">
            <div class="item-column date">{{ item[0] }}</div>
            <div class="item-column time">{{ item[1] }}</div>
            <div class="item-column away">{{ $t(`wbsc.${item[2]}`) }}</div>
            <div class="item-column score">{{ item[3] }}</div>
            <div class="item-column home">{{ $t(`wbsc.${item[4]}`) }}</div>
            <div class="item-column stage">{{ item[5] }}</div>
            <div class="item-column place">{{ item[6] }}</div>
          </div>
        </div>
        <div class="panel-footer">
          <img class="bet-button" src="@/assets/wbsc/bet.png" @click="showTgChannel()" />
        </div>
      </div>
    </div>
    <div class="page-section live">
      <img class="section-title-bg" src="@/assets/wbsc/section-title-bg.png" />
      <img class="section-title" src="@/assets/wbsc/live-title.png" />
      <img class="live-layer" src="@/assets/wbsc/live-layer.png" />
      <img class="live-watch" src="@/assets/wbsc/live-watch.png" @click="showTgChannel()" />
    </div>
    <div class="page-footer">
      <img id="tg" class="tg-qrcode" src="@/assets/wbsc/tg-qrcode.png" />
      <img class="tg-super" src="@/assets/wbsc/tg-super.png" @click="openTGChannel()" />
    </div>
  </div>
</template>
<script>
  import Papa from 'papaparse';

  const csvUrl = 'https://img.q1688.net/agent-img';
  const csvRandom = new Date().getTime();
  const csvConfig = {
    download: true,
    header: false,
    dynamicTyping: false,
    skipEmptyLines: true,
    comments: '#',
  };
  const WBSCTime = new Date('2024-11-10').getTime();
  const AGroupKey = 'a-group';
  const BGroupKey = 'b-group';
  const SuperRoundKey = 'super-round';
  const MatchesKey = 'matchs';
  const makeCsvUrl = (name) => `${csvUrl}/wbsc/${name}.csv?=${csvRandom}`;

  export default {
    data() {
      return {
        mobileMode: false,
        AGroupKey,
        BGroupKey,
        SuperRoundKey,
        activeGroup: '',
        groups: {
          [AGroupKey]: [],
          [BGroupKey]: [],
          [SuperRoundKey]: [],
        },
        groupsLoaded: {
          [AGroupKey]: false,
          [BGroupKey]: false,
          [SuperRoundKey]: false,
        },
        matchs: [],
        countdown: 0,
        countdownInteral: null,
      };
    },
    methods: {
      setActiveGroup(group) {
        this.activeGroup = group;
        if (!this.groupsLoaded[group]) {
          Papa.parse(makeCsvUrl(group), {
            ...csvConfig,
            complete: (results) => {
              this.groups[group] = results.data.sort((row1, row2) => row2[7] - row1[7]);
              this.groupsLoaded[group] = true;
            },
          });
        }
      },
      makeCountdownText() {
        let diff = this.countdown;
        if (diff > 0) {
          const hourSpanTime = 1000 * 60 * 60;
          const minSpanTime = 1000 * 60;
          const daySpanTime = 24 * 60 * 60 * 1000;
          let days = Math.floor(diff / daySpanTime);
          if (days > 0) {
            diff -= days * daySpanTime;
          }
          if (days < 10) {
            days = '0' + days;
          }
          let hours = Math.floor(diff / hourSpanTime);
          if (hours > 0) {
            diff -= hours * hourSpanTime;
          }
          if (hours < 10) {
            hours = '0' + hours;
          }
          let mins = Math.floor(diff / minSpanTime);
          if (mins > 0) {
            diff -= mins * minSpanTime;
          }
          if (mins < 10) {
            mins = '0' + mins;
          }
          let seconds = Math.floor(diff / 1000);
          if (seconds > 0) {
            diff -= seconds * 1000;
          }
          if (seconds < 10) {
            seconds = '0' + seconds;
          }
          return `${days} : ${hours} : ${mins} : ${seconds}`;
        }
        return `00 : 00 : 00 : 00`;
      },
      onPageResize(event) {
        if (window.innerWidth < 720) {
          this.mobileMode = true;
        } else {
          this.mobileMode = false;
        }
      },
      showTgChannel() {
        const element = document.getElementById('tg');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      },
      openTGChannel() {
        window.open('https://t.me/+xYrmaB-x9WFkYWE1', 'tg');
      },
    },
    mounted() {
      window.addEventListener('resize', this.onPageResize, { passive: false, capture: true });
      this.onPageResize();
      this.setActiveGroup(AGroupKey);
      Papa.parse(makeCsvUrl(MatchesKey), {
        ...csvConfig,
        complete: (results) => {
          this.matchs = results.data;
        },
      });
      this.countdownInteral = setInterval(() => {
        const now = new Date().getTime();
        this.countdown = WBSCTime - now;
      }, 1000);
    },
    unmounted() {
      window.removeEventListener('resize', this.onPageResize, { passive: false, capture: true });
      if (this.countdownInteral) {
        clearInterval(this.countdownInteral);
        this.countdownInteral = null;
      }
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = null;
      }
    },
  };
</script>

<style lang="scss">
  .promotion-page {
    position: relative;
    width: 100vw;
    height: 100%;
    background-image: url(~@/assets/wbsc/bg.png);
    background-size: cover;
    background-attachment: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    user-select: none;
    .bet-button {
      width: 16vw;
      cursor: pointer;
      &:hover,
      &:active {
        filter: drop-shadow(1px 2px 3px #fff8);
      }
    }
    > .page-banner {
      position: relative;
      width: 100vw;
      height: 52vw;
      > .banner-layer1 {
        position: absolute;
        width: 100%;
        right: 0;
        bottom: 0;
      }
      > .banner-layer2 {
        position: absolute;
        width: 55%;
        right: 0;
        bottom: 0;
      }
      > .banner-layer3 {
        position: absolute;
        width: 42%;
        left: 38%;
        bottom: 24%;
      }
      > .banner-layer4 {
        position: absolute;
        width: 78%;
        left: 0;
        bottom: 0;
      }
      .banner-countdown1 {
        position: absolute;
        left: 46.5%;
        bottom: 40.8%;
        color: #fffa;
        transform: rotateZ(-2.8deg);
        font-size: 2vw;
        word-spacing: 2.4vw;
      }
      .banner-countdown2 {
        position: absolute;
        left: 46%;
        bottom: 32%;
        color: #fff;
        transform: rotateZ(-2.8deg);
        font-size: 3.6vw;
      }
      > .banner-watch {
        position: absolute;
        width: 20%;
        left: 58%;
        bottom: 17%;
        cursor: pointer;
        &:hover {
          filter: drop-shadow(3px 3px 6px #fff8);
        }
      }
      > .banner-wbsc {
        position: absolute;
        width: 10%;
        right: 2vw;
        top: 2vw;
      }
      > .banner-super {
        position: absolute;
        width: 20%;
        left: 2vw;
        top: 2vw;
        cursor: pointer;
        &:hover {
          filter: drop-shadow(3px 3px 6px #fff8);
        }
      }
      > .banner-home {
        position: absolute;
        width: 5%;
        left: 2vw;
        top: 2vw;
        cursor: pointer;
        &:hover {
          filter: drop-shadow(3px 3px 6px #fff8);
        }
      }
    }
    > .page-section {
      position: relative;
      width: 100vw;
      margin-top: 6vw;
      > .section-title-bg {
        display: block;
        position: relative;
        height: 5vw;
        margin-left: auto;
        margin-right: auto;
      }
      > .section-title {
        display: block;
        position: absolute;
        height: 3vw;
        left: 50%;
        top: 1.2vw;
        transform: translateX(-50%);
      }
      > .section-panel {
        position: relative;
        width: 82vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1.6vw;
        padding: 1.2vw;
        box-shadow: 0.2vw 0.8vw 2vw 0 #0008;
        border-radius: 1.2vw;
        background-image: linear-gradient(
          to bottom,
          rgba(223, 149, 61, 0.8),
          rgba(204, 107, 53, 0.8) 50%,
          rgba(190, 74, 49, 0.8)
        );
        line-height: 1;
        > .panel-tabs {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 1vw;
          margin-bottom: 0.6vw;
          padding: 0.4vw 0.8vw;
          > .panel-tab {
            padding: 0.6vw 2vw 0.4vw;
            border-radius: 2vw;
            border: solid 1px #ffe7b9;
            color: #ffe7b9;
            font-size: 1.6vw;
            letter-spacing: 0.2vw;
            cursor: pointer;
            &.active {
              box-shadow: 1px 2px 4px 0 #0004;
              border-color: #671901;
              background-color: #671901;
            }
            &:hover {
              box-shadow: 1px 2px 4px 0 #fff6;
            }
          }
        }
        > .panel-header {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          position: relative;
          width: 100%;
          margin-bottom: 0.4vw;
          padding-top: 0.8vw;
          padding-bottom: 0.4vw;
          box-shadow: 0.2vw 0.4vw 0.6vw 0 #0004;
          border-radius: 0.4vw;
          background-color: #ffe7b9;
          color: #713b1c;
          font-size: 2vw;
          > .item-column {
            flex: 1 1 4vw;
            text-align: center;
            word-break: keep-all;
            white-space: nowrap;
          }
        }
        > .panel-body {
          position: relative;
          width: 100%;
          min-height: 8vw;
          margin-bottom: 0.8vw;
          > .panel-item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: relative;
            height: 4vw;
            padding-top: 1vw;
            font-size: 1.6vw;
            &::after {
              content: '';
              position: absolute;
              width: 96%;
              height: 1px;
              left: 2%;
              bottom: 0;
              background-color: #fff4;
            }
            &:hover {
              background-image: linear-gradient(
                to right,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0) 1%,
                rgba(255, 255, 255, 0.1) 3%,
                rgba(255, 255, 255, 0.1) 97%,
                rgba(255, 255, 255, 0) 99%,
                rgba(255, 255, 255, 0)
              );
            }
            > .item-column {
              flex: 1 1 4vw;
              text-align: center;
              color: #fff;
              word-break: keep-all;
              white-space: nowrap;
            }
          }
        }
        > .panel-footer {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: end;
          margin-bottom: -1.2vw;
        }
      }
      &.ranking {
        height: 53vw;
        > .ranking-top5 {
          position: absolute;
          width: 76vw;
          left: 50%;
          top: 7vw;
          transform: translateX(-50%);
        }
        > .ranking-top12 {
          position: absolute;
          width: 84vw;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
        }
      }
      &.group {
        > .section-panel {
          .item-column {
            &.team {
              flex: 4 1 8vw;
              > .team-flag {
                position: relative;
                height: 2vw;
                top: -0.1vw;
                margin-left: 2vw;
                margin-right: 0.2vw;
                border: 1px solid #fff8;
                border-radius: 50%;
                vertical-align: middle;
              }
            }
            &.win-rate {
              flex: 2 1 4vw;
            }
            &.tbq {
              flex: 2 1 4vw;
            }
            &.odds {
              flex: 2 1 6vw;
            }
            &.left {
              text-align: left;
            }
          }
        }
      }
      &.schedule {
        > .section-panel {
          background-image: linear-gradient(
            to bottom,
            rgba(179, 57, 72, 0.8),
            rgba(113, 28, 134, 0.8) 50%,
            rgba(60, 24, 137, 0.8)
          );
          > .panel-header {
            background-color: #ffb9b9;
          }
          .item-column {
            &.score {
              flex: 2 1 6vw;
            }
            &.place {
              flex: 3 8vw;
            }
          }
        }
      }
      &.live {
        height: 48vw;
        > .live-layer {
          position: absolute;
          width: 72vw;
          left: 50%;
          top: 7vw;
          transform: translateX(-50%);
        }
        > .live-watch {
          position: absolute;
          width: 16vw;
          left: 44%;
          bottom: 12%;
          cursor: pointer;
          &:hover,
          &:active {
            filter: drop-shadow(1px 2px 3px #fffa);
          }
        }
      }
    }
    > .page-footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      gap: 0.4vw;
      position: relative;
      width: 100vw;
      padding-top: 2vw;
      padding-bottom: 6vw;
      > .tg-qrcode {
        height: 10vw;
      }
      > .tg-super {
        height: 10vw;
        cursor: pointer;
        &:hover,
        &:active {
          filter: drop-shadow(2px 3px 4px #fffa);
        }
      }
    }
    > .page-scroll {
      position: fixed;
      width: min(1vw, 8px);
      height: 98vh;
      right: 4px;
      top: 1vh;
      border-radius: 4px;
      background-color: #fff2;
      overflow: hidden;
      opacity: 0;
      transition: opacity 600ms ease-in;
      > .scroll-bar {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        border-radius: 4px;
        background-color: #fff8;
        cursor: grab;
      }
      &.active {
        opacity: 1;
      }
    }
    &.mobile {
      background-image: url(~@/assets/wbsc/bg-m.png);
      > .ranking {
        height: 72vw;
        > .ranking-top12 {
          position: absolute;
          width: 72vw;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
        }
      }
      > .live {
        height: 58vw;
        > .live-layer {
          width: 86vw;
        }
        > .live-watch {
          width: 18vw;
          bottom: 13%;
        }
      }
      > .page-footer {
        flex-direction: column;
        > .tg-qrcode {
          height: 16vw;
        }
        > .tg-super {
          height: 16vw;
        }
      }
    }
  }
</style>
